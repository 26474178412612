import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PatternLines, PatternCircles } from "@urbaninfrastructure/charts";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "patterns"
    }}>{`Patterns`}</h1>
    <p>{`The patterns is a just a simple wrapper for `}<a parentName="p" {...{
        "href": "https://vx-demo.now.sh/static/docs/vx-pattern.html"
      }}>{`@vx/pattern`}</a>{`, and all props is passed onto it. The patterns should be added in a `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` element before the charts, and reference the ids in the `}<inlineCode parentName="p">{`fill`}</inlineCode>{` property on `}<inlineCode parentName="p">{`data`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {
  BarStackHorizontalSimple,
  PatternLines,
  PatternCircles
} from "@urbaninfrastructure/charts";

const App = () => (
  <>
    <svg width={0} height={0}>
      <PatternLines id="pattern-1" stroke="red" />
    </svg>
    <BarStackHorizontalSimple
      height={20}
      data={[
        {
          key: "New York",
          value: 63.4,
          fill: "url('#pattern-1')"
        }
      ]}
    />
  </>
);
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Since it just renders a svg `}<inlineCode parentName="p">{`pattern`}</inlineCode>{` element, it can be refenced and used for any svg item.`}</p>
    <Playground __position={0} __code={'<svg>\n  <PatternLines id=\"pattern-line-1\" stroke=\"red\" />\n  <PatternCircles id=\"pattern-circle-1\" stroke=\"blue\" />\n  <circle fill=\"url(\'#pattern-line-1\')\" cx=\"50\" cy=\"50\" r=\"50\" />\n  <circle fill=\"url(\'#pattern-circle-1\')\" cx=\"150\" cy=\"50\" r=\"50\" />\n</svg>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PatternLines,
      PatternCircles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <svg>
    <PatternLines id="pattern-line-1" stroke="red" mdxType="PatternLines" />
    <PatternCircles id="pattern-circle-1" stroke="blue" mdxType="PatternCircles" />
    <circle fill="url('#pattern-line-1')" cx="50" cy="50" r="50" />
    <circle fill="url('#pattern-circle-1')" cx="150" cy="50" r="50" />
  </svg>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      